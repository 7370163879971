import React from 'react';
import classNames from 'classnames/bind';
import styles from './About.module.scss';

const cx = classNames.bind(styles);

const Test = () => {
    return <div className={cx('test', 'center')}>
        <h1>QAi</h1>
        <h2>AI face-to-face  for He<span style={{color: 'red'}}>Al</span>thcare Advocates</h2>
        <p>
            ASK STROKE SURVIVOR STACIE A QUESTION
        </p>
        <p>
            Mum-of-three Stacie is here to answer your questions about having a stroke.
            Just click on the microphone – ask any of the 10 questions below (in any order)
            – and click again at the end your question (red box).
        </p>
        <ol>
            <li>1.  What happened when you had your stroke?</li>
            <li>2.  What did you feel when you had your stroke?</li>
            <li>3. What medical treatment did you receive?</li>
            <li>4.  How did you recover?</li>
            <li>5.  Was it hard to stay positive?</li>
            <li>6. Did you have setbacks?</li>
            <li>7. What issues do you still have to deal with?</li>
            <li>8. Do brain exercises help?</li>
            <li>9. Do you feel a burden because of your stroke?</li>
            <li>10. What advice do you have for stroke sufferers?</li>
        </ol>
        <div className={cx('qai')}></div>
    </div>;
};

export default Test;
